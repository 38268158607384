import { ref } from 'vue'

export class WebUtils {
	isWeb 			= ref()
	isVideoWeb		= ref()

	constructor() {
		this.updateVariables()

		window.addEventListener('resize', () => {
			this.updateVariables()
		})
	}

	updateVariables() {
		const windowWidth			= document.documentElement.clientWidth
		const windowHeight			= document.documentElement.clientHeight
		this.isWeb.value			= windowWidth >= 800

		if (windowWidth > windowHeight && windowWidth >= 750 && windowHeight >= 650 ) {
			this.isVideoWeb.value = true
		} else {
			this.isVideoWeb.value = false
		}
	}
}

