export default {
	FEATURE: {
		userName: true,
		userSurname: true,
		userFullName: false,
		userUsername: false,
		userEmail: true,
		userBio: false,
		userSchool: false
	},
	CONFIG: {}
}