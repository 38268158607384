import i18n from "@/plugins/w/translate/index"

export default {
	parseToErrors(data) {
		let errorsArray = this._parseToArrayOfStrings(data)
		console.log(errorsArray)
		return errorsArray.map(error => i18n.global.t(error))
		// return errorsArray.map(error => error)
	},

	_parseToArrayOfStrings(data) {
		if (typeof data == 'string') {
			return [i18n.global.t(data)]
		}
		
		if (data && data.error && typeof data.error == 'string') {
			return [i18n.global.t(data.error)]
		}
		
		if (data && data.error && typeof data.error == 'object') {
			return Object.values(i18n.global.t(data.error))
		}
		
		if (data.data && data.data.error && typeof data.data.error == 'string') {
			return [i18n.global.t(data.data.error)]
		}
		
		if (data && data.data && data.data.error && typeof data.data.error == 'object') {
			console.log(Object.values(data.data.error))
			return Object.values(data.data.error).map((error: string) => i18n.global.t(error[0]) + ' ')

		}
		
		if (data.statusText) {
			return [i18n.global.t(data.statusText)]
		}

		return [data]
	}

}