<template>
	<div>
		<div class="ion-no-padding ion-no-margin ion-margin-vertical cmf-input-wrapper ion-text-left" >
			<ion-label>{{label}}</ion-label>
			<ion-select
				class="ion-no-margin ion-padding-horizontal ion-margin-vertical"
				v-model="fieldData"
				:disabled="readonly"
				@ionChange="changed($event)">


				<ion-select-option :key="index" v-for="(option, index) of options" :value="option.value">{{ option.title }}</ion-select-option>
			</ion-select>
			<div v-if="(v$ && v$[form][name].$dirty ) || (vBe && vBe[name])">
				<img v-if="!hasError" src="../_assets/img/check-input.svg" alt="">
				<img v-if="hasError" src="../_assets/img/x-input.svg" alt="">
			</div>
		</div>
		<div class="error" v-if="v$ && v$[form][name].$invalid && v$[form][name].$dirty">
			<div v-for="(error, index) in v$[form][name].$errors" :key="index">
				{{validationErrors[error.$validator]}}
			</div>
		</div>

		<div class="error" v-if="vBe">
			<div v-for="(error, index) in vBe[name]"  :key="index">
				{{displayError(error)}}
			</div>
		</div>
	</div>
</template>

<script>
import utils from '@/plugins/w/toast/utils'

export default {
	emits: ['update:modelValue'],
	props: {
		modelValue: String,
		label: {
			required: false,
			default: ''
		},
		name: {
			required: true,
			default: ''
		},
		readonly: {
			type: Boolean,
			required: false,
			default: false
		},
		options: {
			default: []
		},
		v$: {
			default: ''
		},
		vBe: {
			type: Array,
			required: false,
		},
		form: {
			type: String,
			default: 'form'
		},
	},
	mounted() {
		this.fieldData = this.modelValue
	},
	watch: {
		modelValue(data) {
			this.fieldData = data
		}
	},
	data(){
		return{
			fieldData: '',
			validationErrors: {
				required: 'This field is mandatory.',
			}
		}
	},
	computed: {
		hasError() {
			if ( this.vBe && this.vBe[this.name]) {
				return true
			} else if (this.v$ && this.v$[this.form][this.name].$dirty && this.v$[this.form][this.name].$invalid) {
				return true
			}

			return false
		}
	},
	methods: {
		changed(e) {
			this.eventBus.emit('input', this.name)
			this.$emit('update:modelValue', e.target.value)
		},
		displayError(error) {
			return utils.parseToString(error)
		}
	}
}
</script>

<style lang="sass" scoped>
ion-select
  border: 1px solid #DCE1E9
  border-radius: 3px
  font-size: 12px
  text-align: left


ion-label
  --margin-bottom: 7px
  text-align: left

.custom-input
  --padding-start: 15px

ion-select::part(icon)
  right: 20px


.cmf-input-wrapper
  position: relative
  img
    position: absolute
    right: 10px
    bottom: 11px
    z-index: 20
  &.validation-enabled
    .custom-select
      background: #fff

</style>
