import {toastController} from '@ionic/vue'
import { ref } from 'vue'
import utils from './utils'

export default {
	_isLoading: ref(false),

	isLoading() {
		return this._isLoading.value
	},
	stopLoading() {
		this._isLoading.value = false
	},
	async error(error='Error', position: any = 'bottom') {
		this._isLoading.value = false

		const toast = await toastController.create({
			message: utils.parseToErrors(error),
			color: 'danger',
			position: position,
			cssClass: 'w-toast',
			duration: 2000
		})

		toast.present()
	},
	async success(message='Success', position: any = 'bottom') {
		this._isLoading.value = false

		const toast = await toastController.create({
			message: utils.parseToErrors(message),
			color: 'success',
			position: position,
			cssClass: 'w-toast',
			duration: 2000
		})

		toast.present()
	},
	clear_loading() {
		this._isLoading.value = true
		// Vue.$toast.clear()
	},
	// clear() {
	// 	Vue.$toast.clear()
	// }
}

