import {
	createI18n, VueI18n
} from 'vue-i18n'
import Translations from './translations'


export const i18n = createI18n({
	legacy: true,
	locale: Translations.getLocale(),
	fallbackLocale: Translations.getDefaultLocale(),
	messages: Translations.loadLocaleMessages(),
	missing: Translations.missingTranslation,
	postTranslation: Translations.postTranslation,
})

export default i18n
