import loginGuardIsLoggedIn from '@/plugins/w/authStore/loginGuardIfMetaSet'

export default (title, path, component, requiresAuth = false) => {
	return {
		path,
		name: title,
		meta: { title, requiresAuth: requiresAuth },
		component: component,
		beforeEnter: loginGuardIsLoggedIn
	}
}
