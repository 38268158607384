import { createRouter, createWebHistory } from '@ionic/vue-router'
import { RouteRecordRaw } from 'vue-router'
import wRoute from '@/plugins/w/route'

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		redirect: '/homepage'
	},
	wRoute('home',       				'/home',       												() => import('@/plugins/lib@enum/themes/themes.vue'), /* true) */),
	wRoute('theme',       				'/theme/:themeSlug',       									() => import('@/plugins/lib@enum/theme/theme.vue'), /* true) */),
	wRoute('youtube-themes',       		'/youtube-theme',       									() => import('@/plugins/lib@enum/youtube-themes/youtube-themes.vue'), /* true) */),
	wRoute('youtube-theme',       		'/youtube-theme/:themeSlug',       							() => import('@/plugins/lib@enum/youtube-theme/youtube-theme.vue'), /* true) */),
	wRoute('course',       				'/course/:courseSlug',       								() => import('@/plugins/lib@course/course/course.vue'), /* true) */),
	wRoute('legacy-course',       		'/legacy-course/:courseSlug/:lessonSlug',       			() => import('@/plugins/lib@course/legacy-course/legacy-course.vue'), /* true) */),
	wRoute('course-feed',       		'/course/:courseSlug/course-feed/:videoId?',    			() => import('@/plugins/lib@feed/course/feed-course.vue'), /* true) */),
	wRoute('lesson-feed',       		'/course/:courseSlug/lesson-feed/:lessonSlug/:videoId?', 	() => import('@/plugins/lib@feed/lesson/feed-lesson.vue'), /* true) */),
	wRoute('personal-feed',       		'/profile/:userId/:type/:videoId?', 						() => import('@/plugins/lib@feed/personal/feed-personal.vue'), /* true) */),
	wRoute('theme-feed',       			'/theme/:themeSlug/theme-feed/:videoId?',      				() => import('@/plugins/lib@feed/theme/feed-theme.vue'), /* true) */),
	wRoute('upload-video',       		'/upload-video',       										() => import('@/plugins/appacademy/lesson/video-form/video-form.vue'), true),
	wRoute('registration',       		'/registration',       										() => import('@/plugins/lib@user/register/register.vue'), /* true) */),
	wRoute('login',       				'/login',       											() => import('@/plugins/lib@user/login/login.vue'), /* true) */),
	wRoute('profile',       			'/profile/:id',       										() => import('@/plugins/lib@profile/profile/profile.vue'),  true ),
	wRoute('forgotpassword',       		'/forgot-password',       									() => import('@/plugins/lib@user/forgot-pwd/forgot-password.vue'), /* true) */),
	wRoute('resetpassword',       		'/reset-password',       									() => import('@/plugins/lib@user/reset-pwd/reset-password.vue'), /* true) */),
	wRoute('projects',       			'/projects',       											() => import('@/plugins/lib@project/projects/projects.vue'), /* true) */),
	wRoute('project',       			'/project/:id',       										() => import('@/plugins/lib@project/project/project.vue'), /* true) */),
	wRoute('edit-project',       		'/edit-project',       										() => import('@/plugins/lib@project/edit-project/edit-project.vue'), /* true) */),
	wRoute('my-projects',       		'/my-projects',       										() => import('@/plugins/lib@project/my-projects/my-projects.vue'), /* true) */),

	//open-academy transfer screens
	wRoute('homepage',       			'/homepage',       											() => import('@/plugins/appacademy/grant/homepage.vue'), /* true) */),
	wRoute('about-us',       			'/about-us',       											() => import('@/plugins/appacademy/grant/about-us.vue'), /* true) */),
	wRoute('for-teachers',       		'/for-teachers',       										() => import('@/plugins/appacademy/grant/for-teachers.vue'), /* true) */),
	

]

const router = createRouter({
	history: createWebHistory(process.env.VUE_APP_BASE_URL),
	routes
})

router.beforeEach((to) => {
	window.postMessage(to.fullPath, '*')
})

export default router
