<template>
	<ion-app>
		<ion-router-outlet :key="$route.name" />
	</ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet } from '@ionic/vue'

export default {
	components: {
		IonApp,
		IonRouterOutlet
	}
}
</script>