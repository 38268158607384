// Devmini
import '@/plugins/w/devmini'

// Moxios
import moxios from '@/plugins/w/axios-mocks'

moxios.mock({
	// 'GET v1/auth/info': true,
	// 'POST v1/auth/info': true,
	// 'POST v1/auth/invalidate': true,
	// 'POST v1/auth/forgot': true,
	// 'POST v1/auth/reset': true,
	// 'POST v1/auth/login': true,
	// 'POST v1/auth/signup': true,
	// 'GET v1/themes/(id)': true,
	// 'GET v1/1_themes': true,
	// 'GET v1/courses/tags': true,
	// 'GET v1/courses/(id)': true,
})