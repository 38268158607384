<template>
	<div>
		<div class="ion-no-padding ion-no-margin ion-margin-vertical cmf-input-wrapper ion-text-left" >
			<p>{{label}}</p>
			<v-date-picker :model-config="modelConfig" v-model="fieldData">
				<template v-slot="{ inputEvents }">
					<div class="ion-no-padding ion-no-margin">
						<ion-input
							v-on="inputEvents"
							v-model="fieldData"
							:disabled="readonly"
							:name="name"
							:type="type"
							:placeholder="placeholder"
							class="ion-no-margin custom-input ion-margin-vertical"
							@ionChange="changed($event)"
						/>
					</div>
				</template>
			</v-date-picker>
			<div v-if="(v$ && v$[form][name].$dirty ) || (vBe && vBe[name])">
				<img v-if="!hasError" src="../_assets/img/check-input.svg" alt="">
				<img v-if="hasError" src="../_assets/img/x-input.svg" alt="">
			</div>
		</div>
		<div class="error" v-if="v$ && v$[form][name].$invalid && v$[form][name].$dirty">
			<div v-for="(error, index) in v$[form][name].$errors" :key="index">
				{{validationErrors[error.$validator]}}
			</div>
		</div>

		<div class="error" v-if="vBe">
			<div v-for="(error, index) in vBe[name]"  :key="index">
				{{displayError(error)}}
			</div>
		</div>
	</div>
</template>
<script>

import utils from '@/plugins/w/toast/utils'

export default {
	emits: ['update:modelValue'],
	props: {
		modelValue: String,
		label: {
			required: false,
			default: ''
		},
		name: {
			required: true,
			default: ''
		},
		type: {
			type: String,
			required: false,
			default: 'text'
		},
		placeholder: {
			type: String,
			required: false,
			default: ''
		},
		readonly: {
			type: Boolean,
			required: false,
			default: false
		},
		vBe: {
			type: Array,
			required: false,
		},
		form: {
			type: String,
			default: 'form'
		},
		v$: {
			default: null
		},
	},
	mounted() {
		this.fieldData = this.modelValue
	},
	watch: {
		modelValue(data) {
			this.fieldData = data
		}
	},
	computed: {
		hasError() {
			if ( this.vBe && this.vBe[this.name]) {
				return true
			} else if (this.v$ && this.v$[this.form][this.name].$dirty && this.v$[this.form][this.name].$invalid) {
				return true
			}

			return false
		}
	},
	data() {
		return {
			fieldData: null,
			validationErrors: {
				required: 'This field is mandatory.',
			},
			modelConfig: {
				type: 'string',
				mask: 'DD.MM.YYYY',
			},
		}
	},
	methods: {
		changed(e) {
			this.eventBus.emit('input', this.name)
			this.$emit('update:modelValue', e.target.value)
		},
		displayError(error) {
			return utils.parseToString(error)
		}
	}

}
</script>
<style lang="sass" scoped>
.cmf-input-wrapper
  position: relative
  img
    position: absolute
    right: 10px
    bottom: 10px
    z-index: 20

ion-input
  border: 1px solid #DCE1E9
  border-radius: 3px
  font-size: 12px
  text-align: left

.custom-input
  --padding-start: 15px
</style>
