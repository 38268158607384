export default {
	FEATURE: {
		onboardingAllowSkip: true
	},
	CONFIG: {
		finishButtonText: 'Start your journey',
		redirectToAfterFinish: 'home',
		redirectToIfUserIsLogged: 'home',
		pages: [{
			image: 'plugins/appacademy/_theme/img/logo-white.png',
			text: 'Break, the rules of traditional education and become 1% better everyday.  Whit delivers bite-sized content that makes you smarter everytime.',
			backgroundImage: 'plugins/appacademy/onboarding/ONBOARDING_IMG_1.png'
		}, {
			// image: 'plugins/appacademy/_theme/img/logo-white.png',\
			text: 'Become sharp-witted in no time. Whit’s bite-sized video content makes learning easy and fun anytime, anywhere you are.',
			backgroundImage: 'plugins/appacademy/onboarding/ONBOARDING_IMG_2.png'
		}, {
			title: 'Share what you know',
			text: 'Your knowledge thrives here. Bring your passions and add an extra source of income by sharing what you already know. ',
			backgroundImage: 'plugins/appacademy/onboarding/ONBOARDING_IMG_3.png'
		}]
	}
}