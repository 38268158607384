import onboarding from './onboarding.config'
import userSettings from './userSettings.config'
import userRegister from './userRegister.config'
import comments from './comments.config'

export default {
	CONFIG: {
		onboarding: onboarding.CONFIG,
		userSettings: userSettings.CONFIG
	},
	FEATURE: {
		...userSettings.FEATURE,
		...userRegister.FEATURE,
		...onboarding.FEATURE,
		...comments.FEATURE
	}
}