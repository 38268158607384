// Vue init
import { createApp } from 'vue'
import App from '@/App.vue'
import { IonicVue } from '@ionic/vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'


import router from '@/plugins/appacademy/router'
import store from '@/store'
import '@/custom.shared'
import './plugins.config'
import '@/plugins/w/devmini/index'

import  i18n  from  '@/plugins/w/translate/index'
import  Translations  from  '@/plugins/w/translate/translations'

Translations.setup('sk', { //default language
	en:  'English',
	sk:  'Slovenčina' //additional languages
})

const app = createApp(App)
	.use(IonicVue)
	.use(router)
	.use(store)
	.use(i18n)
  
// Add ionic components
import {registerIonicComponents} from '@/plugins/w/ionic'
registerIonicComponents(app)

router.isReady().then(() => {
	app.mount('#app')
})

// App web version
// import { isWeb, isVideoWeb } from '@/plugins/appacademy/_utils/isWeb.util'
// app.config.globalProperties.$isWeb = isWeb()
// app.config.globalProperties.$isVideoWeb = isVideoWeb()
// window.addEventListener('resize', () => {
// 	console.log('resize')
// 	console.log(isWeb())
// 	app.config.globalProperties.$isWeb = isWeb()
// 	app.config.globalProperties.$isVideoWeb = isVideoWeb()
// })

// Themes
import './_theme/index.sass'

// Featureconfig
import wConfig from '@/plugins/w/featureconfig/index'
import defaultConfig from '@/config/default'
import customConfig from './_config/config'

wConfig.addConfigAndFeatureVariables(app, customConfig, defaultConfig)

//Fields
import CmfInput from '@/plugins/cmf/cmf-input/cmf-input.vue'
import CmfSelect from '@/plugins/cmf/cmf-select/cmf-select.vue'
import CmfCheckbox from '@/plugins/cmf/cmf-checkbox/cmf-checkbox.vue'
import CmfDatePicker from '@/plugins/cmf/cmf-date-picker/cmf-date-picker.vue'
import CmfTextarea from '@/plugins/cmf/cmf-textarea/cmf-textarea.vue'
import WLoadable from '@/plugins/w/loadable/w-loadable.vue'

app.component('cmf-input', CmfInput)
app.component('cmf-select', CmfSelect)
app.component('cmf-checkbox', CmfCheckbox)
app.component('cmf-date-picker', CmfDatePicker)
app.component('cmf-textarea', CmfTextarea)
app.component('w-loadable', WLoadable)


// Event emitter
import mitt from 'mitt'
const eventBus = mitt()
app.config.globalProperties.eventBus = eventBus

// Toast
import wToast from '@/plugins/w/toast'
app.config.globalProperties.$wToast = wToast

// Loader
import wLoader from '@/plugins/w/loader'
app.config.globalProperties.$wLoader = wLoader

// Web utils
import { WebUtils } from '@/plugins/lib@utils/screen.utils'
const webUtils = new WebUtils()
app.config.globalProperties.$isWeb 		= webUtils.isWeb
app.config.globalProperties.$isVideoWeb = webUtils.isVideoWeb

if (process.env.NODE_ENV === "development") {
	localStorage.setItem('isDev', 'true')
}

if (process.env.NODE_ENV === "production") {
	localStorage.setItem('isDev', 'false')
}
