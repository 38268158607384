import userRegister from '@/plugins/w@screen/register/config/userRegister.config'
import userSettings from '@/plugins/w@screen/user-settings/config/userSettings.config'
import onboarding from '@/plugins/w@screen/onboarding/config/onboarding.config'

export default {
	FEATURE: {
		...userRegister.FEATURE,
		...userSettings.FEATURE,
		...onboarding.FEATURE
	},
	CONFIG: {
		userRegister: userRegister.CONFIG,
		userSettings: userSettings.CONFIG,
		onboarding: onboarding.CONFIG
	}
}